import React, { Fragment,useState,useEffect } from 'react'
import { useSelector } from 'react-redux'
import SweetAlert from "sweetalert2";
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';
import {firebase_app, auth0} from './data/config';
import {BrowserRouter,Switch,Route,Redirect, useHistory} from 'react-router-dom'
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react'
import store from './store'
import App from './components/app'
import { CSSTransition,TransitionGroup } from 'react-transition-group'
import {routes} from './route';
import ConfigDB  from './data/customizer/config'
import {configureFakeBackend ,authHeader, handleResponse} from './services/fack.backend'

// Dashboard
import Default from './components/dashboard/default'

// Mutation
import Mutation from "./components/mutation/index"

// Signin page
import Signin from './auth/signin'

// Authentication
import Login from "./pages/authentication/login"
import LoginWithValidation from "./pages/authentication/loginwithValidation"
import Register from "./pages/authentication/register"
import UnlockUser from "./pages/authentication/unlockUser"
import Forgetpwd from "./pages/authentication/forgetpwd"
import Resetpwd from "./pages/authentication/resetpwd"

// Error page
import Error400 from "./pages/errors/error400"
import Error401 from "./pages/errors/error401"
import Error403 from "./pages/errors/error403"
import Error404 from "./pages/errors/error404"
import Error500 from "./pages/errors/error500"
import Error503 from "./pages/errors/error503"

// Maintenanc
import Maintenance from "./pages/maintenance"

import Callback from './auth/callback'

import axios from 'axios';


// setup fake backend
// configureFakeBackend();







const Root = (props) =>  {
  const token = useSelector(state => state.userReducer.data.token)
// console.log("token :", token);
  // const DEV = `https://neko01.otlet.id/api`;
  // const PROD = `https://kfc.otlet.id/api`;

  // axios.defaults.baseURL = `${process.env.BASE_URL}/api`
  // axios.defaults.baseURL = `https://kfc.otlet.id/api`
  axios.defaults.baseURL = `https://neko01.otlet.id/api`
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  axios.defaults.headers.post['Content-Type'] = 'application/json';

  const history = useHistory()
  const [anim, setAnim] = useState("");
  const animation = localStorage.getItem("animation") || ConfigDB.data.router_animation || 'fade'
  const abortController = new AbortController();
  const [currentUser, setCurrentUser] = useState(false);
  const [authenticated,setAuthenticated] = useState(true)
  const jwt_token = localStorage.getItem('token');

  const [sweetAlert, setSweetAlert] = useState(false);
  const DisplayAlertBackToLogin = () => {
    // e.prevenDefault();
    setSweetAlert(true);
    SweetAlert
    .fire({ title: "Token Expired", 
    text: "Your Token Expired, Please Login Again", 
    icon: 'warning',
    confirmButtonText: window.location.pathname === (`${process.env.PUBLIC_URL}/login`) ? 'Ok' : 'Back To Login',
    confirmButtonColor: '#f25c00', 
  })
    .then((result) => {
      if (result.isConfirmed && window.location.pathname !== (`${process.env.PUBLIC_URL}/login`)) {
       window.location.href = (`${process.env.PUBLIC_URL}/login`)
      }
    })
  };


  const verifyToken = () => {
    axios
    .get(`verify-token`)
    .then(res =>{
      if (res.status === 'ok'){
        console.log('verify token: ', res)
        return res
      }
    })
    .catch(err =>{
      localStorage.removeItem('reduxState')
      DisplayAlertBackToLogin()
    })
  }

  

  const dataUser = useSelector(state => state.userReducer.data.user)
  // console.log("data user: ", dataUser)

  useEffect(() => {

    if(window.location.pathname !== `${process.env.PUBLIC_URL}/login`) {
      verifyToken()
    }
      
      // eslint-disable-next-line 
    }, []);

  //   const [role, setRole] = useState(
  //     (localStorage.getItem('Role') || '').split(',')
  // );


  const hasPermissions = (user, permissions) => {
    // console.log("permission :", permissions)
    if(permissions === undefined){
      return true  
    } else if (permissions instanceof Array){
      return permissions.some(permission => user.permissions.includes(permission))
    } else {
      return user.permissions.includes(permissions)
    }
  };
    // const hasRole = (user, roles) => roles.some(role => user.roles.includes(role));
    // const hasRoleAdmin = (admin, roles) => roles.some(role => admin.roles.includes(role));

 

    return(
      <Fragment>
        {/* <Auth0Provider domain={auth0.domain} clientId={auth0.clientId} redirectUri={auth0.redirectUri}> */}
        {/* <Provider store={store}> */}
        <BrowserRouter basename={`/`}>
        <Switch>
          
          
          <Route  path={`${process.env.PUBLIC_URL}/login`} component={Signin} />
          <Route  path={`${process.env.PUBLIC_URL}/pages/auth/login`} component={Login}></Route>
          <Route  path={`${process.env.PUBLIC_URL}/pages/auth/loginWithValidation`} component={LoginWithValidation}></Route>
          <Route  path={`${process.env.PUBLIC_URL}/pages/auth/signup`} component={Register}></Route>
          <Route  path={`${process.env.PUBLIC_URL}/pages/auth/forgetPwd`} component={Forgetpwd}></Route>
          <Route  path={`${process.env.PUBLIC_URL}/pages/auth/unlockUser`} component={UnlockUser}></Route>
          <Route  path={`${process.env.PUBLIC_URL}/pages/auth/resetPwd`} component={Resetpwd}></Route>

          <Route  path={`${process.env.PUBLIC_URL}/pages/errors/error400`} component={Error400}></Route>
          <Route  path={`${process.env.PUBLIC_URL}/pages/errors/error401`} component={Error401}></Route>
          <Route  path={`${process.env.PUBLIC_URL}/pages/errors/error403`} component={Error403}></Route>
          <Route  path={`${process.env.PUBLIC_URL}/pages/errors/error404`} component={Error404}></Route>
          <Route  path={`${process.env.PUBLIC_URL}/pages/errors/error500`} component={Error500}></Route>
          <Route  path={`${process.env.PUBLIC_URL}/pages/errors/error503`} component={Error503}></Route>

          <Route  path={`${process.env.PUBLIC_URL}/pages/maintenance`} component={Maintenance}></Route>
          
          <Route  path={`${process.env.PUBLIC_URL}/callback`} render={() => <Callback/>} />
          
          {dataUser  ?
          
          <App>

          <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
              return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />)
          }} /> 

          <TransitionGroup>
            {/* {hasRole(admin, ['admin']) && <Route exact  path={`/dashboard`}>
            {({ match }) => (
                        <CSSTransition 
                          in={match != null}
                          timeout={100}
                          classNames={anim} 
                          unmountOnExit>
                          <div><Default/></div>
                        </CSSTransition> 
                    )}
              </Route>}
              {hasRole(admin, ['user']) && <Route exact  path={`/mutation`}>
            {({ match }) => (
                        <CSSTransition 
                          in={match != null}
                          timeout={100}
                          classNames={anim} 
                          unmountOnExit>
                          <div><Mutation/></div>
                        </CSSTransition> 
                    )}
              </Route>} */}
              {routes.map(({path, Component, permission}) => (
                <div key={path}>
                  {hasPermissions(dataUser, permission) && <Route exact  path={`${process.env.PUBLIC_URL}${path}`}>
                    {/* {console.log("data roles : ", roles)}
                    {console.log("data user : ", user)} */}
                    {({ match }) => (
                        <CSSTransition 
                          in={match != null}
                          timeout={100}
                          classNames={"fade"} 
                          unmountOnExit>
                          <div><Component/></div>
                        </CSSTransition> 
                    )}
                </Route> }
                
                </div>
                ))}
          </TransitionGroup> 
          
          
          </App>
          :
          <Redirect to={`${process.env.PUBLIC_URL}/login`} />
          }      
        </Switch>
        </BrowserRouter>
        {/* </Provider> */}
        {/* </Auth0Provider> */}
      </Fragment>
      )
}

ReactDOM.render( 
<Provider store={store}>
<Root/>
</Provider>,
  document.getElementById('root')
);
serviceWorker.unregister();
